import React from "react"

export default function You() {
  return (
    <React.Fragment key="you">

      <div>
        <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
          <p className="text-base font-semibold tracking-wider text-center text-white uppercase">
            <div className="">
                <div className="sm:flex sm:items-baseline sm:justify-between">
                  <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">¿Qué necesito para ser franquiciado?</h2>
                </div>

                <div className="grid grid-cols-1 mt-6 gap-y-6 sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-6 lg:gap-8">
                  <div className="overflow-hidden rounded-lg group aspect-w-2 aspect-h-1 sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2">
                    <img
                      src="https://images.pexels.com/photos/935756/pexels-photo-935756.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                      alt="Puedes abrir una franquicia Tannity sin ningún tipo de experiencia previa, nosotros te formamos."
                      className="object-cover object-center group-hover:opacity-75"
                    />
                    <div aria-hidden="true" className="opacity-50 bg-gradient-to-b from-transparent to-black" />
                    <div className="flex items-end p-6">
                      <div>
                        <h3 className="font-semibold text-white">
                            <span className="absolute inset-0 text-base" />
                            Sin experiencia
                        </h3>
                        <p aria-hidden="true" className="mt-1 text-sm text-white">
                          No necesitas ningún tipo de titulación previa, te formamos.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="overflow-hidden rounded-lg group aspect-w-2 aspect-h-1 sm:relative sm:aspect-none sm:h-full">
                    <img
                      src="https://images.pexels.com/photos/259165/pexels-photo-259165.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                      alt="No necesitas grandes ahorros para abrir una franquicia de bronceado saludable Tannity."
                      className="object-cover object-center group-hover:opacity-75 sm:absolute sm:inset-0 sm:w-full sm:h-full"
                    />
                    <div
                      aria-hidden="true"
                      className="opacity-50 bg-gradient-to-b from-transparent to-black sm:absolute sm:inset-0"
                    />
                    <div className="flex items-end p-6 sm:absolute sm:inset-0">
                      <div>
                        <h3 className="font-semibold text-white">
                            <span className="absolute inset-0 text-base" />
                            Sin grandes ahorros
                        </h3>
                        <p aria-hidden="true" className="mt-1 text-sm text-white">
                          Te ayudamos a obtener financiación para tu franquicia.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="overflow-hidden rounded-lg group aspect-w-2 aspect-h-1 sm:relative sm:aspect-none sm:h-full">
                    <img
                      src="https://images.pexels.com/photos/4498574/pexels-photo-4498574.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                      alt="En Tannity buscamos compañeros con ganas."
                      className="object-cover object-center group-hover:opacity-75 sm:absolute sm:inset-0 sm:w-full sm:h-full"
                    />
                    <div
                      aria-hidden="true"
                      className="opacity-50 bg-gradient-to-b from-transparent to-black sm:absolute sm:inset-0"
                    />
                    <div className="flex items-end p-6 sm:absolute sm:inset-0">
                      <div>
                        <h3 className="font-semibold text-white">
                            <span className="absolute inset-0 text-base" />
                            Ganas
                        </h3>
                        <p aria-hidden="true" className="mt-1 text-sm text-white">
                          El ingrediente más importante que necesitas son muchas ganas.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </p>
        </div>
      </div>

    </React.Fragment>
  )
}