import React from "react"

export default function Celebrities() {
  return (
    <React.Fragment key="celebrities">

      <div className="py-8 lg:py-24">
        <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div>
            <h2 className="mb-4 text-4xl text-center">El nuevo secreto de belleza de influencers y <span className="italic">celebrities</span></h2>
          </div>
          <div className="relative px-8 py-24 overflow-hidden bg-yellow-500 shadow-2xl rounded-xl lg:px-16 lg:grid lg:grid-cols-2 lg:gap-x-8">
            <div className="absolute inset-0 opacity-50 filter saturate-0 mix-blend-multiply">
              <img
                src="https://cdn.shopify.com/s/files/1/0557/3496/0288/files/laura.jpg?v=1642424768"
                alt="Foto de la directora de comunicación de Tannity"
                className="object-cover w-full h-full"
              />
            </div>
            <div className="relative lg:col-span-1">
              <blockquote className="mt-6 text-white">
                <p className="text-lg font-medium sm:text-xl">
                  Tannity es el centro de bronceado de referencia de las <span className="italic">celebrities</span> y los influencers en España.
                </p>
                <br />
                <p className="text-lg font-medium sm:text-xl">
                  A nuestros centros vienen más de <span className="font-bold">600 <span className="italic">celebrities</span> e influencers</span> a lo largo del año.
                </p>
              </blockquote>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  )
}