import React from 'react'
import { PopupButton } from '@typeform/embed-react'

export default function Hero() {

  function handlePixels() {

    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq('track', 'SubmitApplication', {});
      }

      if (window.gtag != null) {
        window.gtag('event', 'begin_checkout', {})
      }
    }
  }

  return (
    <React.Fragment key="hero">

      <div>
        <div className="flex items-center justify-center px-8 md:w-1/2 md:h-96">
          <div className="flex-col">
            <h1 className="mb-4 text-4xl text-center">La franquicia del bronceado saludable, sin sol ni rayos UVA.</h1>
            <p className="mb-4 text-center">¡Únete ahora y abre tu centro en menos tiempo del que imaginas!</p>
            
            <div className="mb-4 text-center">
              <PopupButton id="lOuDhSJj" className="text-center my-form">
                <button
                  type="button"
                  className="items-center px-4 py-2 text-base font-medium text-white bg-yellow-500 border border-transparent rounded-md shadow-sm hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                  onClick={() => {
                    handlePixels()
                  }}
                >
                    Obtener información
                </button>
              </PopupButton>
            </div>
          </div>
        </div>

        <div className="md:block md:absolute md:inset-y-0 md:right-0 lg:right-8 md:w-1/2 md:h-96">
          {/*<img
            className="hidden object-cover w-full h-full md:block"
            src="https://cdn.shopify.com/s/files/1/0557/3496/0288/files/horizontal.jpg?v=1638782934"
            alt="Tannity models"
          />*/}
          <video
            playsinline="true"
            loop="true"
            autoplay="true"
            muted="true"
            className="hidden object-cover w-full h-full md:block"
          >
            <source
              src="https://cdn.shopify.com/s/files/1/0557/3496/0288/files/alicante_vid_optimized.mp4?v=1644316751"
              type="video/mp4"
            />
          </video>
          <video
            playsinline="true"
            loop="true"
            autoplay="true"
            muted="true"
            className="object-cover w-screen md:hidden"
          >
            <source
              src="https://cdn.shopify.com/s/files/1/0557/3496/0288/files/tannity_vertical_valencia.mp4?v=1643724681"
              type="video/mp4"
            />
          </video>
        </div>

      </div>

    </React.Fragment>
  )
}