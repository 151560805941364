import React from "react"

export default function Info() {
  return (
    <React.Fragment key="info">

        <div className="container relative px-4 mx-auto text-white sm:px-6 lg:px-8">
          <div className="relative px-4 py-8 mx-auto max-w-7xl sm:py-12 sm:px-6 lg:py-16">
            <div className="max-w-2xl mx-auto lg:max-w-none lg:ml-auto lg:w-1/2">
              <h2 className="mb-4 text-3xl font-extrabold text-center sm:text-4xl">
                ¿Qué es Tannity?
              </h2>
              <p className="text-lg text-center">
                El nuevo concepto de bronceado saludable sin sol ni rayos UVA que está revolucionando la industria del bronceado en España. 
              </p>
            </div>
          </div>
          <div className="pb-8 bg-yellow-600">
            <img
              className="object-cover w-full h-full md:h-96 md:object-contain"
              src="https://cdn.shopify.com/s/files/1/0557/3496/0288/files/desktop.jpg?v=1652794501"
              alt="Tannity model"
            />
            <div className="mt-8 overflow-hidden">
              <dl className="flex flex-wrap -mx-8 -mt-8 md:flex-row">
                <div className="flex flex-col px-8 pt-8 text-center md:flex-grow">
                  <dt className="order-2 text-base font-medium">1 sesión 15 minutos</dt>
                  <dd className="order-1 text-2xl font-extrabold sm:text-3xl">Tratamiento</dd>
                </div>
                <div className="flex flex-col px-8 pt-8 text-center md:flex-grow">
                  <dt className="order-2 text-base font-medium">5/7 días</dt>
                  <dd className="order-1 text-2xl font-extrabold sm:text-3xl">Duración</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>

    </React.Fragment>
  )
}