import React from "react"
import { PopupButton } from '@typeform/embed-react'
import { MapIcon, MailIcon, AcademicCapIcon, PhoneIcon, CashIcon, OfficeBuildingIcon } from '@heroicons/react/solid'

const timeline = [
  {
    id: 1,
    content: '1- ',
    target: 'Dossier',
    href: '#',
    date: 'Sep 20',
    datetime: '2020-09-20',
    icon: MailIcon,
    iconBackground: 'bg-gray-400',
  },
  {
    id: 2,
    content: '2- ',
    target: 'Hablamos',
    href: '#',
    date: 'Sep 22',
    datetime: '2020-09-22',
    icon: PhoneIcon,
    iconBackground: 'bg-blue-500',
  },
  {
    id: 3,
    content: '3- ',
    target: 'Reserva',
    href: '#',
    date: 'Sep 28',
    datetime: '2020-09-28',
    icon: CashIcon,
    iconBackground: 'bg-yellow-300',
  },
  {
    id: 4,
    content: '4- ',
    target: 'Localización',
    href: '#',
    date: 'Sep 30',
    datetime: '2020-09-30',
    icon: MapIcon,
    iconBackground: 'bg-red-500',
  },
  {
    id: 5,
    content: '5- ',
    target: 'Formación',
    href: '#',
    date: 'Oct 4',
    datetime: '2020-10-04',
    icon: AcademicCapIcon,
    iconBackground: 'bg-purple-500',
  },
    {
    id: 6,
    content: '6- ',
    target: 'Apertura',
    href: '#',
    date: 'Oct 4',
    datetime: '2020-10-04',
    icon: OfficeBuildingIcon,
    iconBackground: 'bg-green-500',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Steps() {
  function handlePixels() {

    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq('track', 'SubmitApplication', {});
      }

      if (window.gtag != null) {
        window.gtag('event', 'begin_checkout', {})
      }
    }
  }

  return (
    <React.Fragment key="steps">
      <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
        <div className="mb-8">
          <h2 className="text-4xl text-center text-white">Pasos para abrir tu franquicia Tannity</h2>
        </div>
        <div className="flow-root">
          <ul className="-mb-8">
            {timeline.map((event, eventIdx) => (
              <li key={event.id} className="flex justify-center min-w-full">
                <div className="relative w-48 pb-8">
                  {eventIdx !== timeline.length - 1 ? (
                    <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                  ) : null}
                  <div className="relative flex space-x-6">
                    <div>
                      <span
                        className={classNames(
                          event.iconBackground,
                          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                        )}
                      >
                        <event.icon className="w-5 h-5 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                      <div>
                        <p className="text-sm text-white md:text-base">
                          {event.content}{' '}
                          <a href={event.href} className="font-medium text-white">
                            {event.target}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex justify-center mt-8">
          <PopupButton id="lOuDhSJj" style={{ width: '50%' }} className="my-form">
            <button 
              type="button" 
              onClick={() => {
                handlePixels()
              }}
              className="inline-flex items-center px-4 py-2 text-base font-medium text-white bg-yellow-500 border border-transparent rounded-md shadow-sm hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-600">
                  Empezar
            </button>
          </PopupButton>
        </div>
      </div>
    </React.Fragment>
  )
}