import React from "react"

export default function Press() {
  return (
    <React.Fragment key="press">

      <div>
        <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
          <p className="text-base font-semibold tracking-wider text-center text-white uppercase">
            El nuevo concepto de bronceado saludable en los medios
          </p>
          <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
            <div className="flex justify-center col-span-1 px-8 py-8 bg-gray-50">
              <a href="https://www.elmundo.es/vida-sana/cuerpo/2021/06/14/60c713c6e4d4d80f038b4687.html" target="_blank" rel="noreferrer">
                <img
                  className="max-h-12"
                  src="https://cdn.shopify.com/s/files/1/0557/3496/0288/files/elmundo.webp?v=1638797591"
                  alt="Artículo de El Mundo sobre Tanity"
                />
              </a>
            </div>
            <div className="flex justify-center col-span-1 px-8 py-8 bg-gray-50">
              <a href="https://www.elle.com/es/belleza/cara-cuerpo/a36593707/tannity-bronceado-saludable-influencers/" target="_blank" rel="noreferrer">
                <img className="max-h-12" src="https://cdn.shopify.com/s/files/1/0557/3496/0288/files/elle.webp?v=1638797591" alt="Artículo de Elle sobre Tannity" />
              </a>
            </div>
            <div className="flex justify-center col-span-1 px-8 py-8 bg-gray-50">
              <a href="https://www.hola.com/belleza/20210609191128/bronceado-sin-sol-tratamientos-piel" target="_blank" rel="noreferrer">
                <img className="max-h-12" src="https://cdn.shopify.com/s/files/1/0557/3496/0288/files/hola.webp?v=1638797591" alt="Artículo de Hola sobre Tannity" />
              </a>
            </div>
            <div className="flex justify-center col-span-1 px-8 py-8 bg-gray-50">
              <a href="https://www.20minutos.es/salud/alternativas-a-tomar-el-sol-para-broncear-la-piel-sin-rayos-uva-artificales-4709750" target="_blank" rel="noreferrer">
                <img className="max-h-12" src="https://cdn.shopify.com/s/files/1/0557/3496/0288/files/20minutos.webp?v=1638797591" alt="Artículo de 20minutos sobre Tannity" />
              </a>
            </div>
            <div className="flex justify-center col-span-1 px-8 py-8 bg-gray-50">
              <a href="https://www.telva.com/belleza/cuerpo/2021/06/05/60b9f97b01a2f1467c8b457f.html" target="_blank" rel="noreferrer">
                <img
                  className="max-h-12"
                  src="https://cdn.shopify.com/s/files/1/0557/3496/0288/files/telva.webp?v=1638797591"
                  alt="Artículo de Telva sobre Tannity"
                />
              </a>
            </div>
            <div className="flex justify-center col-span-1 px-8 py-8 bg-gray-50">
              <a href="https://www.vanitatis.elconfidencial.com/estilo/belleza/2020-06-16/autobronceadores-moreno-inmediato_2639492/" target="_blank" rel="noreferrer">
                <img
                  className="max-h-12"
                  src="https://cdn.shopify.com/s/files/1/0557/3496/0288/files/vanitatis.webp?v=1638797591"
                  alt="Artículo de Vanitatis sobre Tannity"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  )
}