import React from "react"

import {
  AcademicCapIcon,
  ClockIcon,
  StarIcon,
  CloudIcon,
  KeyIcon,
  MapIcon
} from '@heroicons/react/outline'

const actions = [
  {
    title: 'Llave en mano',
    href: '#',
    icon: KeyIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-gray-100',
    description: 'Dejamos todo listo para la apertura y que puedas empezar a facturar desde el primer día.'
  },
  {
    title: 'Apertura express',
    href: '#',
    icon: ClockIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-green-100',
    description: 'Desde la firma del contrato de franquicia, nuestro equipo se pone manos a la obra para relizar la apertura en aproximadamente dos meses.'
  },
  {
    title: 'Financiación',
    href: '#',
    icon: MapIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-pink-100',
    description: 'Te facilitamos hasta un 70% de la inversión.'
  },
  { title: 'App de gestión', 
    href: '#', 
    icon: CloudIcon, 
    iconForeground: 'text-teal-700', 
    iconBackground: 'bg-blue-100',
    description: 'Contarás con un software cloud accesible desde cualquier dispositivo para gestionar tu franquicia en cualquier momento y desde cualquier lugar con total comodidad.' 
  },
  {
    title: 'Formación desde cero',
    href: '#',
    icon: AcademicCapIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-indigo-100',
    description: 'Formaremos a ti y/o tu personal en el arte del bronceado y también en la gestión de la franquicia y el software a utilizar.'
  },
  {
    title: 'Valor de marca',
    href: '#',
    icon: StarIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-yellow-100',
    description: 'El bronceado saludable favorito de las influencers.'
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Benefits() {
  return (
    <React.Fragment key="benefits">
      <div className="relative px-4 pb-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="pt-8 pb-4">
          <h2 className="text-4xl text-center text-white">Beneficios Tannity</h2>
        </div>
        <div className="overflow-hidden divide-y divide-gray-200 rounded-lg shadow sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
          {actions.map((action, actionIdx) => (
            <div
              key={action.title}
              className={classNames(
                actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-yellow-500'
              )}
            >
              <div className="">
                <h3 className="flex items-center text-lg font-medium">
                  <span
                    className={classNames(
                      action.iconBackground,
                      action.iconForeground,
                      'rounded-lg inline-flex p-3 ring-4 ring-white mr-4'
                    )}
                  >
                    <action.icon className="w-6 h-6" aria-hidden="true" />
                  </span>
                  {/*<a href={action.href} className="focus:outline-none">*/}
                     
                    <span className="absolute inset-0" aria-hidden="true" />
                    {action.title}
                  {/*</a>*/}
                </h3>
                <p className="mt-2 text-sm text-gray-500">
                  {action.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  )
}