import React, { useState } from 'react'

import Layout from '../components/structure/layout.js'
import Hero from '../components/sections/hero.js'
import Info from '../components/sections/info.js'
import Benefits from '../components/sections/benefits.js'
import Press from '../components/sections/press.js'
import Celebrities from '../components/sections/celebrities.js'
import Steps from '../components/sections/steps.js'
import You from '../components/sections/you.js'
import Table from '../components/sections/table.js'

export default function Home() {
  {/* Facebook and Google píxels */}
  const [flagFbPixel, setflagFbPixel] = useState(false)
  const [flagGooglePixel, setflagGooglePixel] = useState(false)

  if (typeof window !== "undefined") {
    if (window.fbq != null && flagFbPixel === false) {
      window.fbq('track', 'ViewContent', {content_category: 'landing', content_name: 'landing_franquicia'});
      setflagFbPixel(true)
    }

    if (window.gtag != null && flagGooglePixel === false) {
      window.gtag('event', 'view_item', {})
      setflagGooglePixel(true)
    }

    if (window.linktrk != null) {
      window.lintrk('track', { conversion_id: 6626810 });
    }
  }

  return (
    <Layout>
      <main className="">
        <section className="container relative px-4 mx-auto mb-8 sm:px-6 lg:px-8 lg:mb-16">
          <Hero/>
        </section>

        <section className="mb-8 bg-yellow-600 lg:mb-16">
          <Info/>
        </section>

        <section className="mb-8 lg:mb-16 ">
          <Celebrities/>
        </section>

        <section className="mb-8 bg-yellow-600 lg:mb-16">
          <Press/>
        </section>

        <section className="mb-8 lg:mb-16">
          <You/>
        </section>

        <section className="mb-8 bg-yellow-600 lg:mb-16">
          <Benefits/>
        </section>

        <section className="mb-8 lg:mb-16">
          <Table/>
        </section>

        <section className="bg-yellow-600">
          <Steps/>
        </section>
        
      </main>
    </Layout>
  )
}
