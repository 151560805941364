import React from "react"

import { CheckIcon, XIcon } from '@heroicons/react/outline'

const plans = [
  {
    title: 'Tannity',
    featured: true,
    description: 'Franquicia de bronceado saludable.',
    priceMonthly: 5,
    priceYearly: 56,
    mainFeatures: [
      { id: 1, value: 'Basic invoicing' },
      { id: 2, value: 'Easy to use accounting' },
      { id: 3, value: 'Mutli-accounts' },
    ],
  },
  {
    title: 'Rayos UV',
    featured: false,
    description: 'Franquicias de bronceado con rayos UVA.',
    priceMonthly: 19,
    priceYearly: 220,
    mainFeatures: [
      { id: 1, value: 'Advanced invoicing' },
      { id: 2, value: 'Easy to use accounting' },
      { id: 3, value: 'Mutli-accounts' },
      { id: 4, value: 'Tax planning toolkit' },
      { id: 5, value: 'VAT & VATMOSS filing' },
      { id: 6, value: 'Free bank transfers' },
    ],
  },
  {
    title: 'Otras franquicias',
    featured: false,
    description: 'Otras franquicias relacionadas con el mundo de la belleza.',
    priceMonthly: 12,
    priceYearly: 140,
    mainFeatures: [
      { id: 1, value: 'Basic invoicing' },
      { id: 2, value: 'Easy to use accounting' },
      { id: 3, value: 'Mutli-accounts' },
      { id: 4, value: 'Tax planning toolkit' },
    ],
  },
]
const features = [
  {
    title: 'Inversión total',
    tiers: [
      { title: 'starter', featured: true, value: '38.000€' },
      { title: 'popular', featured: false, value: '> 100.000€' },
      { title: 'intermediate', value: '> 50.000€' },
    ],
  },
  {
    title: 'Canon apertura',
    tiers: [
      { title: 'starter', featured: true, value: 'Sin canon' },
      { title: 'popular', featured: false, value: '>10.000€' },
      { title: 'intermediate', value: '>10.000€' },
    ],
  },
  {
    title: 'Royalty mensual',
    tiers: [
      { title: 'starter', featured: true, value: '550€ mes' },
      { title: 'popular', featured: false, value: '> 600€ mes + variable' },
      { title: 'intermediate', value: '> 600€ mes + variable' },
    ],
  },
  {
    title: 'Canon publicidad',
    tiers: [
      { title: 'starter', featured: true, value: 'Sin canon'},
      { title: 'popular', featured: false, value: '>300€ mes' },
      { title: 'intermediate', value: '>300€ mes' },
    ],
  },
  {
    title: 'Ayuda financiera',
    tiers: [
      { title: 'starter', value: true },
      { title: 'popular', featured: false, value: false },
      { title: 'intermediate', value: false },
    ],
  },
  {
    title: 'Llave en mano',
    tiers: [
      { title: 'starter', value: true },
      { title: 'popular', featured: false, value: false },
      { title: 'intermediate', value: false },
    ],
  },
  {
    title: 'Formación',
    tiers: [
      { title: 'starter', value: true },
      { title: 'popular', featured: false, value: false },
      { title: 'intermediate', value: false },
    ],
  },
  {
    title: 'Saludable',
    tiers: [
      { title: 'starter', value: true },
      { title: 'popular', featured: false, value: false },
      { title: 'intermediate', value: true },
    ],
  },
]
const perks = [
  {
    title: '24/7 customer support',
    tiers: [
      { title: 'starter', value: true },
      { title: 'popular', featured: true, value: true },
      { title: 'intermediate', value: true },
    ],
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Table() {
  return (
    <React.Fragment key="table">
      <div className="bg-white">
        <div className="relative">
          {/* Overlapping background */}
          <div aria-hidden="true" className="absolute bottom-0 hidden w-full h-6 lg:block" />

          <div className="relative max-w-2xl px-4 pt-16 mx-auto text-center sm:pt-32 sm:px-6 lg:max-w-7xl lg:px-8">
            <h1 className="text-4xl font-extrabold tracking-tight text-black sm:text-6xl">
              <span className="block lg:inline">Elige Tannity.</span>
            </h1>
            <p className="mt-4 text-xl text-black">
              Comparativa frente a rayos UVA y otros centros de belleza.
            </p>
          </div>
        </div>


        {/* Feature comparison (up to lg) */}
        <section aria-labelledby="mobile-comparison-heading" className="lg:hidden">

          <div className="max-w-2xl px-4 py-16 mx-auto space-y-16 sm:px-6">
            {plans.map((plan, mobilePlanIndex) => (
              <div key="plan.title" className="border-t border-gray-200">
                <div
                  className={classNames(
                    plan.featured ? 'border-yellow-600' : 'border-transparent',
                    '-mt-px pt-6 border-t-2 sm:w-1/2'
                  )}
                >
                  <h3 className={classNames(plan.featured ? 'text-yellow-600' : 'text-gray-900', 'text-sm font-bold')}>
                    {plan.title}
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">{plan.description}</p>
                </div>
                

                <div className="relative mt-6">
                  {/* Fake card background */}
                  <div aria-hidden="true" className="absolute inset-0 hidden pointer-events-none sm:block">
                    <div
                      className={classNames(
                        plan.featured ? 'shadow-md' : 'shadow',
                        'absolute right-0 w-1/2 h-full bg-white rounded-lg'
                      )}
                    />
                  </div>

                  <div
                    className={classNames(
                      plan.featured ? 'ring-2 ring-yellow-600 shadow-md' : 'ring-1 ring-black ring-opacity-5 shadow',
                      'relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none'
                    )}
                  >
                    <dl className="divide-y divide-gray-200">
                      {features.map((feature) => (
                        <div
                          key={feature.title}
                          className="flex items-center justify-between py-3 sm:grid sm:grid-cols-2"
                        >
                          <dt className="pr-4 text-sm font-medium text-gray-600">{feature.title}</dt>
                          <dd className="flex items-center justify-end sm:px-4 sm:justify-center">
                            {typeof feature.tiers[mobilePlanIndex].value === 'string' ? (
                              <span
                                className={classNames(
                                  feature.tiers[mobilePlanIndex].featured ? 'text-yellow-600' : 'text-gray-900',
                                  'text-sm font-medium'
                                )}
                              >
                                {feature.tiers[mobilePlanIndex].value}
                              </span>
                            ) : (
                              <>
                                {feature.tiers[mobilePlanIndex].value === true ? (
                                  <CheckIcon className="w-5 h-5 mx-auto text-yellow-600" aria-hidden="true" />
                                ) : (
                                  <XIcon className="w-5 h-5 mx-auto text-gray-400" aria-hidden="true" />
                                )}

                                <span className="sr-only">
                                  {feature.tiers[mobilePlanIndex].value === true ? 'Yes' : 'No'}
                                </span>
                              </>
                            )}
                          </dd>
                        </div>
                      ))}
                    </dl>
                  </div>

                  {/* Fake card border */}
                  <div aria-hidden="true" className="absolute inset-0 hidden pointer-events-none sm:block">
                    <div
                      className={classNames(
                        plan.featured ? 'ring-2 ring-yellow-600' : 'ring-1 ring-black ring-opacity-5',
                        'absolute right-0 w-1/2 h-full rounded-lg'
                      )}
                    />
                  </div>
                </div>

                <div className="relative mt-6">

                  {/*}
                  <div
                    className={classNames(
                      plan.featured ? 'ring-2 ring-yellow-600 shadow-md' : 'ring-1 ring-black ring-opacity-5 shadow',
                      'relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none'
                    )}
                  >
                    <dl className="divide-y divide-gray-200">
                      {perks.map((perk) => (
                        <div key={perk.title} className="flex justify-between py-3 sm:grid sm:grid-cols-2">
                          <dt className="text-sm font-medium text-gray-600 sm:pr-4">{perk.title}</dt>
                          <dd className="text-center sm:px-4">
                            {perk.tiers[mobilePlanIndex].value === true ? (
                              <CheckIcon className="w-5 h-5 mx-auto text-yellow-600" aria-hidden="true" />
                            ) : (
                              <XIcon className="w-5 h-5 mx-auto text-gray-400" aria-hidden="true" />
                            )}

                            <span className="sr-only">{perk.tiers[mobilePlanIndex].value === true ? 'Yes' : 'No'}</span>
                          </dd>
                        </div>
                      ))}
                    </dl>
                  </div>*/}

                  {/* Fake card border */}
                  <div aria-hidden="true" className="absolute inset-0 hidden pointer-events-none sm:block">
                    <div
                      className={classNames(
                        plan.featured ? 'ring-2 ring-yellow-600' : 'ring-1 ring-black ring-opacity-5',
                        'absolute right-0 w-1/2 h-full rounded-lg'
                      )}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Feature comparison (lg+) */}
        <section aria-labelledby="comparison-heading" className="hidden lg:block">
          <h2 id="comparison-heading" className="sr-only">
            Feature comparison
          </h2>

          <div className="px-8 py-24 mx-auto max-w-7xl">
            <div className="flex items-stretch w-full border-t border-gray-200">
              <div className="flex items-end w-1/4 py-6 pr-4 -mt-px">
                <h3 className="mt-auto text-sm font-bold text-gray-900">Tabla comparativa</h3>
              </div>
              {plans.map((plan, planIdx) => (
                <div
                  key={plan.title}
                  aria-hidden="true"
                  className={classNames(planIdx === plans.length - 1 ? '' : 'pr-4', '-mt-px pl-4 w-1/4')}
                >
                  <div
                    className={classNames(plan.featured ? 'border-yellow-600' : 'border-transparent', 'py-6 border-t-2')}
                  >
                    <p className={classNames(plan.featured ? 'text-yellow-600' : 'text-gray-900', 'text-sm font-bold')}>
                      {plan.title}
                    </p>
                    <p className="mt-2 text-sm text-gray-500">{plan.description}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="relative">
              {/* Fake card backgrounds */}
              <div className="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
                <div className="w-1/4 pr-4" />
                <div className="w-1/4 px-4">
                  <div className="w-full h-full bg-white rounded-lg shadow" />
                </div>
                <div className="w-1/4 px-4">
                  <div className="w-full h-full bg-white rounded-lg shadow-md" />
                </div>
                <div className="w-1/4 pl-4">
                  <div className="w-full h-full bg-white rounded-lg shadow" />
                </div>
              </div>

              <table className="relative w-full">
                <caption className="sr-only">Business feature comparison</caption>
                <thead>
                  <tr className="text-left">
                    <th scope="col">
                      <span className="sr-only">Feature</span>
                    </th>
                    {plans.map((plan) => (
                      <th key={plan.title} scope="col">
                        <span className="sr-only">{plan.title} plan</span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-100">
                  {features.map((feature) => (
                    <tr key={feature.title}>
                      <th scope="row" className="w-1/4 py-3 pr-4 text-sm font-medium text-left text-gray-600">
                        {feature.title}
                      </th>
                      {feature.tiers.map((tier, tierIdx) => (
                        <td
                          key={tier.title}
                          className={classNames(
                            tierIdx === feature.tiers.length - 1 ? 'pl-4' : 'px-4',
                            'relative w-1/4 py-0 text-center'
                          )}
                        >
                          <span className="relative w-full h-full py-3">
                            {typeof tier.value === 'string' ? (
                              <span
                                className={classNames(
                                  tier.featured ? 'text-yellow-600' : 'text-gray-900',
                                  'text-sm font-medium'
                                )}
                              >
                                {tier.value}
                              </span>
                            ) : (
                              <>
                                {tier.value === true ? (
                                  <CheckIcon className="w-5 h-5 mx-auto text-yellow-600" aria-hidden="true" />
                                ) : (
                                  <XIcon className="w-5 h-5 mx-auto text-red-400" aria-hidden="true" />
                                )}

                                <span className="sr-only">{tier.value === true ? 'Yes' : 'No'}</span>
                              </>
                            )}
                          </span>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Fake card borders */}
              <div className="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
                <div className="w-1/4 pr-4" />
                <div className="w-1/4 px-4">
                  <div className="w-full h-full rounded-lg ring-1 ring-yellow-600 ring-opacity-100" />
                </div>
                <div className="w-1/4 px-4">
                  <div className="w-full h-full rounded-lg ring-2 ring-black ring-opacity-5" />
                </div>
                <div className="w-1/4 pl-4">
                  <div className="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
                </div>
              </div>
            </div>

            {/*
            <h3 className="mt-10 text-sm font-bold text-gray-900">Other perks</h3>

            <div className="relative mt-6">
              
              <div className="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
                <div className="w-1/4 pr-4" />
                <div className="w-1/4 px-4">
                  <div className="w-full h-full bg-white rounded-lg shadow" />
                </div>
                <div className="w-1/4 px-4">
                  <div className="w-full h-full bg-white rounded-lg shadow-md" />
                </div>
                <div className="w-1/4 pl-4">
                  <div className="w-full h-full bg-white rounded-lg shadow" />
                </div>
              </div>

              <table className="relative w-full">
                <thead>
                  <tr className="text-left">
                    <th scope="col">
                      <span className="sr-only">Perk</span>
                    </th>
                    {plans.map((plan) => (
                      <th key={plan.title} scope="col">
                        <span className="sr-only">{plan.title} plan</span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-100">
                  {perks.map((perk) => (
                    <tr key={perk.title}>
                      <th scope="row" className="w-1/4 py-3 pr-4 text-sm font-medium text-left text-gray-600">
                        {perk.title}
                      </th>
                      {perk.tiers.map((tier, tierIdx) => (
                        <td
                          key={tier.title}
                          className={classNames(
                            tierIdx === perk.tiers.length - 1 ? 'pl-4' : 'px-4',
                            'relative w-1/4 py-0 text-center'
                          )}
                        >
                          <span className="relative w-full h-full py-3">
                            {tier.value === true ? (
                              <CheckIcon className="w-5 h-5 mx-auto text-yellow-600" aria-hidden="true" />
                            ) : (
                              <XIcon className="w-5 h-5 mx-auto text-gray-400" aria-hidden="true" />
                            )}

                            <span className="sr-only">{tier.value === true ? 'Yes' : 'No'}</span>
                          </span>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>

            </div>
            */}
          </div>
        </section>
      </div>
    </React.Fragment>
  )
}